import * as React from 'react';
import {Box,Typography,Button} from '@mui/material';
import { GET_CASE_STUDIES } from "../../../api/casestudy";
import axios from 'axios';
// import Box from '@mui/joy'
import CircularProgress from '@mui/joy/CircularProgress';

const CaseStudy = () => {
  const [caseStudies, setCaseStudies] = React.useState([]);
  const [caseStudy1, setCaseStudy1] = React.useState({});
  const [caseStudy2, setCaseStudy2] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    axios
    .get(GET_CASE_STUDIES)
    .then(res => {
      setCaseStudies(res.data.data);
      setCaseStudy1(res.data.data[0]);
      setCaseStudy2(res.data.data[1]);

      setLoading(false);
    })
    .catch(err => {
    })
  },[])

  return (
    <div style={{borderRadius:'10px' , padding:'4%' , lineHeight:'1' ,  boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',}}>
        <div style={{display:'flex' , padding:'1%' , justifyContent:'space-between' , marginBottom:'10px'}}>
            <h2  style={{textAlign:'left'}}>Case Studies</h2>
            <Button href="/casestudy" variant='contained' size='small' style={{background:'#6a58c6' , borderRadius:'10px' , fontSize:'12px' , height:'40px'}}>View All</Button>
            </div>

        <div style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between' , marginBottom:'10px'}}>
        <Box
        style={{
            height:'30px',
            marginBottom: '10px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
        }}
        >
            <Typography >Case 1: </Typography>
            <Typography>&nbsp;{caseStudy1?.title&&caseStudy1?.title.substring(0,caseStudy1.title?.indexOf(':'))}</Typography>
        </Box>
       
        <Box style={{display:'flex' , justifyContent:'center' , alignItems:'center'}}>
        <Button href={`../casestudy/${caseStudy1?._id}`} variant="contained" size="small" style={{backgroundColor:'#6a58c6' , color:'#fff'}}>
        Go To Case
        </Button>
        </Box>    
        </div>
        <div style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between'}}>
        <Box
        style={{
            height:'30px',
            marginBottom: '10px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
        }}
        >
            <Typography >Case 2: </Typography>
            <Typography>&nbsp;{caseStudy2?.title&&caseStudy2?.title.substring(0,caseStudy2?.title.indexOf(':'))}</Typography>
        </Box>
       
        <Box style={{display:'flex' , justifyContent:'center' , alignItems:'center'}}>
        <Button href={`../casestudy/${caseStudy2?._id}`} variant="contained" size="small" style={{backgroundColor:'#6a58c6' , color:'#fff'}}>
        Go To Case
        </Button>
        </Box>         
        </div>

    </div>
  )
}

export default CaseStudy;