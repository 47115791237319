import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TextField , Typography } from '@mui/material';
import axios from 'axios';
import { SEND_EMAIL } from '../../api/casestudy';
import CircularProgress from '@mui/material/CircularProgress';
import { validateEmail } from "../auth/validateEmail";


export default function ResponsiveDialog({isMobile}) {
  const [open, setOpen] = React.useState(false);
  const [email , setEmail] = React.useState("");
  const [wNumber , setWNumber] = React.useState("");
  const [name , setName] = React.useState("");
  const [responded , setResponed] = React.useState(false);
  const [loading , setLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");
  const [contactNoError, setContactNoError] = React.useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email.");
      }
    }
  }, [email]);

  React.useEffect(() => {
    if (!wNumber) {
      setContactNoError("");
    } else {
      if (wNumber.length === 10) {
        setContactNoError("");
      } else {
        setContactNoError("Please Enter valid contactNo.");
      }
    }
  }, [wNumber]);
  const handleEmail = () => {
    setLoading(true);
    axios
    .post(SEND_EMAIL+"/"+window.localStorage.id , {email: email , whatsappNumber: wNumber , name: name })
    .then(res => {
        setLoading(false);
        setResponed(true);
    })
    .catch(err => {
    })
  }

  return (
    <React.Fragment >
    <div style={{width:isMobile?'100%':'80%', display: 'flex' , justifyContent:isMobile?'center':'flex-start' }}>
      <Button 
      variant="outlined" onClick={handleClickOpen} style={{ 
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px' ,
         width:isMobile?'80%':'70%' ,
          height: '40px' ,  
          border:'solid 2px',
          borderColour:'#6a58c6'  , 
           fontFamily:'Poppins' , 
           fontWeight: 'bold',
        textTransform: 'none',
        color: '#6a58c6'}}>
        Download Brochure
      </Button>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
      {loading &&(<DialogContent> <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: isMobile ? '300px' : '500px',
              }}
            >
              <CircularProgress style={{ color: '#6A58C6' }} />
            </div> </DialogContent>) }
      {!loading && responded ? (
        <DialogContent>
        <div style={{dislay:'flex' , alignItems:'center' , justifyContent:'center' , }}>
            <Typography variant="h6">You will be receiving the brochure soon to join Virtual Startup Campus Community</Typography>
        </div>
        <DialogActions>
          <Button variant='contained' style={{backgroundColor:'#6a58c6'}} autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>

        </DialogContent>
      ): (
        !loading&&<>
        <DialogTitle id="responsive-dialog-title">
          Download Virtual Startup Campus Brochure
        </DialogTitle>
        <DialogContent>
        <DialogContentText>
            To Get Our Brochure, please enter the details here. We
            will send it soon.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e)=> setEmail(e.target.value)}
          />
          <span style={{ color: "red" }}>{emailError}</span>

          <TextField
            autoFocus
            margin="dense"
            id="wNumbers"
            label="Whatsapp Number"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e)=> setWNumber(e.target.value)}
          />
          <span style={{ color: "red" }}>{contactNoError}</span>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e)=> setName(e.target.value)}
          />
          </DialogContent>
        <DialogActions>
          <Button variant='contained' style={{backgroundColor:'#6a58c6'}} autoFocus onClick={handleClose}>
            Close
          </Button>
          <Button variant='contained' style={{backgroundColor:'#6a58c6'}} onClick={handleEmail} autoFocus>
            Submit
          </Button>
        </DialogActions>
        </>
      )}
        
      </Dialog>
    </React.Fragment>
  );
}
