import React from "react";
import { useStyles } from "./VirtualCampusStyle";
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from "react";
import { validateEmail } from "../../auth/validateEmail";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Typography } from "@material-ui/core";
import { CREATE_VC } from "../../../api/virtualcampus";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { GET_COURSES } from "../../../api/vsc";
import { APPLY_COURSE } from "../../../api/course";
import Footer from "../../Footer/Footer";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const VirtualCampus = () => {
  const [isAuth, setIsAuth] = React.useState(window.localStorage.getItem('isAuth') || false);
  const navigate = useNavigate();
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [startupIdea, setStartupIdea] = useState("No");
  const [coFounder, setCoFounder] = useState("");
  const [open, setOpen] = useState(false);
  const [currencies , setCurrencies] = useState([])
  const [courseId  , setCourseId] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [contactNoError, setContactNoError] = useState("");
  const [submitted , setSubmitted] = useState("");

  useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email.");
      }
    }
  }, [email]);

  useEffect(() => {
    if (!contactNo) {
      setContactNoError("");
    } else {
      if (contactNo.length === 10) {
        setContactNoError("");
      } else {
        setContactNoError("Please Enter valid contactNo.");
      }
    }
  }, [contactNo]);
  useEffect(() => {
    axios
    .get(GET_COURSES)
    .then((data) => {
      const fetchedCourses = data.data.data.map((course) => ({
        value: course._id,
        label: course.courseName,
      }));
      setCurrencies(fetchedCourses);
    })
    .catch((err) => {
    });
  }, []);
  const MakeAllFalse = () => {
    setFName("");
    setLName("");
    setContactNo("");
    setEmail("");
    setCollegeName("");
    setStartupIdea("");
    setCoFounder("");
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      fName: fName,
      lName: lName,
      wNumber: contactNo,
      email: email,
      description: collegeName,
    };
    if(window.localStorage.id){
      axios
      .post(APPLY_COURSE+courseId+"/"+window.localStorage.id, data)
      .then((res) => {
        MakeAllFalse();
        setSubmitted(true);
        // setOpen(true);
        setTimeout(() => {
          isAuth? navigate('/dashboard/'+window.localStorage.id):navigate('/login' , {state:{redirect:'/dashboard/'+window.localStorage.id}})
        }, 6000);
      })
      .catch((err) => {
        if (err.response && err.response.status === 400 && err.response.data.message === "Already applied for this course") {
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 6000);
        }
      });
    }
    else {
      axios
      .post(APPLY_COURSE+courseId, data)
      .then((res) => {
        MakeAllFalse();
        setSubmitted(true);
        // setOpen(true);
        setTimeout(() => {
          isAuth? navigate('/dashboard/'+window.localStorage.id):navigate('/login' , {state:{redirect:'/dashboard/'+window.localStorage.id}})
        }, 6000);
      })
      .catch((err) => {
        if (err.response && err.response.status === 400 && err.response.data.message === "Already applied for this course") {
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 6000);
        }
      });
    }
    
  };
  const classes = useStyles();
  const styles = {
    radioButton: {
      display: "inline-block",
      marginRight: "5px",
      backgroundColor: "red",
      borderRadius: "50%",
      width: "30px",
      height: "30px",
    },
    label: {
      display: "flex",
      fontFamily: "sans-serif",
      fontSize: "20px",
      fontWeight: "bold",
      color: "black",
    },
  };
  return (
    <>
      <div className={classes.fullpage}>
        <div className={classes.centerdiv}>
          <Typography variant="h3" className={classes.head}>
            Apply Now
          </Typography>
          <div className={classes.formdiv}>
          {submitted ? (
            <>
            <Typography variant="h6" className={classes.head}>
            The form was submitted successfully.
          </Typography>
          <Typography variant="body2" className={classes.head}>
            Click <a href="https://chat.whatsapp.com/L4QMRnE0xQgLPh7uQA0dgo" style={{}}>Here</a> To Join The Whatsapp Group
          </Typography>
          </>
          ): (
            
            <form className={classes.form} onSubmit={handleSubmit}>
              <div className={classes.rowinput}>
                <div className={classes.inputcontainer}>
                  <input
                    type="text"
                    class={classes.input_box}
                    required
                    value={fName}
                    onChange={(e) => setFName(e.target.value)}
                    placeholder="First Name"
                  />
                </div>
                <div className={classes.inputcontainer}>
                  <input
                    type="text"
                    class={classes.input_box}
                    required
                    value={lName}
                    onChange={(e) => setLName(e.target.value)}
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <span style={{ color: "red" }}>{contactNoError}</span>
              <div className={classes.rowinput}>
                <input
                  type="text"
                  class={classes.input_box2}
                  required
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                  placeholder="Contact No."
                />
              </div>
              <span style={{ color: "red" }}>{emailError}</span>
              <div className={classes.rowinput}>
                <input
                  type="email"
                  class={classes.input_box2}
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
              <div className={classes.rowinput}>
                <input
                  type="text"
                  class={classes.input_box2}
                  value={collegeName}
                  onChange={(e) => setCollegeName(e.target.value)}
                  placeholder="College Name / Organization / Not Applicable (N/A)"
                />
              </div>
              <div className={classes.rowinput}>
                <div
                  class={classes.input_box2}
                  style={{
                    color: "black",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily:'Poppins'
                  }}
                >
                  Which Course Are you Interested In ?
                </div>
              </div>
              <div className={classes.rowinput}>
              <TextField
          id="outlined-select-currency"
          select
          label="Select Course"
          className={classes.input_box2}
          InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins' }
            // style={styles.label}
          }}
          // style={{
          //   background:'#fff',
          //   borderRadius:'5px',
          //   borderColor:'#000',
          //   width:'96%'
          // }}
          onChange={(e) => setCourseId(e.target.value)}
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
              </div>
              <div className={classes.rowinput}>
                <button type="submit" className={classes.submitbtn}>
                  Submit
                </button>
              </div>
              <div className={classes.rowinput}>
                <button  onClick={() => navigate('/')}className={classes.submitbtn}>
                  Home
                </button>
              </div>
            </form>
          
          )}
          </div>
        </div>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="info"
            sx={{ width: "100%" }}
          >
            You Have Already Applied For This Course.
          </Alert>
        </Snackbar>

      </div>
      {/* <Footer/> */}

    </>
  );
};

export default VirtualCampus;
