import React from 'react';
import { Box,Typography, Button } from "@mui/material";
import { Divider } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import Singlecard from './Singlecard';
import { blogs } from './testmonialData';
import '../../stylesheets/virtualCampusStartup.css'



const Testimonial = ({from}) => {
  const [loading, setLoading] = React.useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isSmallMobile = useMediaQuery('(max-width: 947px)');
  const isGreaterMobile = useMediaQuery('(max-width: 700px)');
  const isMMobile = useMediaQuery('(max-width: 1109px)')

  const getNumBlogsPerSlide = () => {
    if (isGreaterMobile) {
      return 1;
    } else if (isSmallMobile) {
      return 2;
    } else {
      return !from || from === 'show'? 3: 2;
    }
  };
  let chunkSize = getNumBlogsPerSlide();
  const arrowStyles = {
    position: "absolute",
    top: ".7em",
    bottom: "auto",
    padding: ".4em",
    zIndex: 2,
 };
 const dividerStyle = {
  bgcolor: '#FFBD59',
  height: '2px',
  width:'100%',
};
  const blogChunks = blogs.length > 0 ? blogs.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []) : [];
  return (
      <div style={{marginTop:!from && '70px'}}>
        {!from && (<Box display="flex" justifyContent="center">
        <div style={{ display: 'flex', alignItems: 'center' , flexDirection:isMobile&&'column' }}>
        <Typography className="textVSC"  fontWeight={800}
          // style={{ color: '#000', marginRight: !isMobile&&'10px',  textAlign:'center' }}
          sx={{
    color: '#6A58C6',
    fontSize: { xs: '30px', sm: '2rem', md: '48px' },
    fontWeight: 700,
    lineHeight: '40px',
    fontFamily: `"Poppins", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    margin: '18px 0px 24px 0px',
    textAlign: 'center',
  }}
          >
          Our Students
        </Typography>
        <Typography  fontWeight={800} className="textVSC"  
        // style={{ color: '#6a58c6' ,   textAlign:'center'  }}
        sx={{
    color: '#6A58C6',
    fontSize: { xs: '30px', sm: '2rem', md: '48px' },
    fontWeight: 700,
    lineHeight: '40px',
    fontFamily: `"Poppins", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    margin: '18px 0px 24px 0px',
    textAlign: 'center',
  }}
        >
          Testimonials
          <Divider sx={dividerStyle} />
        </Typography>
      </div>

          </Box>)}
          {!from && (<Box
            display="flex"
            justifyContent="center"
            marginTop="10px"
            textAlign="center"
          >
            <Typography variant="h6" style={{color:'#000'}}>
            Our Focussed approach to gamified learning <br/> and project based work experience oriented programs have helped students take their career ahead
            </Typography>
          </Box>)}
          <Box
            style={{marginTop:'30px'}}
          >
            <Carousel
      className="carousel"
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        background: 'white', 
      }}
      autoPlay={true}
      interval={6000}
      infiniteLoop={true}
      showArrows={true}
      showIndicators={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{
              ...arrowStyles,
              left: !isGreaterMobile?'0.2em':0 ,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#6a58c6',
              color: '#fff',
              borderRadius: '50%',
              border: 'none',
              padding: '10px',
              zIndex: 1,
            }}
          >
            <ArrowBackIosNewRoundedIcon />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{
              ...arrowStyles,
              right: !isGreaterMobile?'0.1em':'0',
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#6a58c6',
              color: '#fff',
              borderRadius: '50%',
              border: 'none',
              padding: '10px',
              zIndex: 1,
            }}
          >
            <ArrowForwardIosRoundedIcon />
          </button>
        )
      }
    >
      {blogChunks.length>0&&blogChunks.map((chunk, chunkIndex) => (
        <div key={chunkIndex} style={{ display: 'flex', flexDirection: 'row' , padding:!isGreaterMobile?'20px':'5px' , background:'#fff', justifyContent:'space-evenly' }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            {chunk.map((item, index) => (
               <Singlecard testimonial={item}/>
          ))}
              
            </Box>
          </div>
        ))}
        
      </Carousel>
          </Box>
          {!from && <Box style={{display:'flex' , justifyContent:'center'}}>
            <Button variant="contained" style={{background:'#6a58c6' , fontFamily:'Poppins' , fontWeight:'600' , height:'100%' , width:'200px' , fontSize:'16x' , lineHeight:'24px' ,padding:'0.8rem' }}>Join Our Community</Button>
          </Box>}
          </div>
  )
}

export default Testimonial;