import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import Axios from "axios";
import { GET_EVENT_ANNOUNCEMENT } from "../../../api/announcement";
function NotificationBar({setClosed}) {
    const [notif, setNotif] = React.useState(true);
    const [body, setBody] = useState("");
    const [title, setTitle] = useState();
    const [link , setLink] = useState("");
    useEffect(() => {
        Axios.get(GET_EVENT_ANNOUNCEMENT).then((response) => {
          if(response.data === "No documents found") {setNotif(false); setClosed(true); return <></>}
          setBody(response.data.body);
          setTitle(response.data.title);
          setLink(response.data.link);
        }).catch(err => {
          console.error(err)
        });
      },[]);
  return (
    <>
    {
      body&&title&&notif&&link?
            <>
                  <div style={{background:'#F0F0F0', backdropFilter: 'blur(15px)', marginBottom: "0px", paddingBottom: "8px",paddingTop:"8px", textAlign: "center" , zIndex:'100000' }}>
                  <div>
                  <a href={link} target="_blank" style={{textDecoration: "none"}}>
                    <span  style={{color: "#6A58C6",textDecoration: "underline", fontWeight: "bold",marginLeft : "10px"}}>{title}</span>
                    {"  "}
                    <span  style={{color: "#000", textDecoration: "none", fontWeight: "600"}}>{body}</span>
                  </a>
                  <CloseIcon style={{float: "right"}} onClick={()=>{setNotif(false); setClosed(true)}}/>
                  </div>
                  </div>
            </>
            : null
          }
    </>
  )
}

export default NotificationBar