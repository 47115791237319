import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Divider from "@mui/material/Divider";
import { NavLink } from "react-router-dom";
import { Badge } from "@mui/material";
import axios from 'axios';
import { GET_DASH_ANNOUNCEMENT } from "../../../api/announcement";
import { GET_APPROVED_CERTIFCATES, GET_APPROVED_CERTIFCATES_WORKEX, POST_OPENED } from "../../../api/certificate";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
export default function Notifications() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [link, setLink] = useState('');
  const [count, setCount] = useState(0);
  const [program , setProgram] = useState([]);
  const [workex , setWorkex] = useState([]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    axios.get(GET_DASH_ANNOUNCEMENT)
      .then(res => {
        if(res.data === "No documents found") { return <></>}
        if(res.data.link){
         
          setTitle(res.data.title);
          setBody(res.data.body);
          setLink(res.data.link);
          res.data.title && setCount(1);
        }
        
      })
      .catch(err => {
        console.error("Error fetching announcement:", err);
      });
      
  }, []);
  useEffect(() => {
    axios
      .get(GET_APPROVED_CERTIFCATES+window.localStorage.id)
      .then(res => {
        setProgram(res.data.data);
        setCount(prevState => prevState+res.data.data.length);
      })
      .catch(err => {
        console.error(err);
      })
      axios
      .get(GET_APPROVED_CERTIFCATES_WORKEX+window.localStorage.id)
      .then(res => {
        setWorkex(res.data.data);
        setCount(prevState => prevState+res.data.data.length);
      })
      .catch(err => {
        console.error(err);
      })
  },[])
  const handleDownload = (link , courseName) => {
    axios
    .post(POST_OPENED+window.localStorage.id , {courseName: courseName})
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.error(err);
    })
    const anchor = document.createElement('a');
    anchor.href = link;
    anchor.download = link.split('/').pop(); // Extracts the filename from the link
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Notifications">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "notification-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Badge badgeContent={count} color="secondary">
              <NotificationsNoneIcon fontSize='large' style={{ color: '#000' }} />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="notification-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 4,
          sx: {
            width: 300,
            maxHeight: '100%', 
            overflowY: "auto", 
            "& .MuiTypography-root": {
              whiteSpace: "normal",
              my: 1,
            },
          },
        }}
      >
       {title && link && body && (
  link ? (
    <NavLink onClick={(e) => window.location.replace(link)} exact style={{ textDecoration: "none", color: "black" }}>
      <MenuItem onClick={handleClose} sx={{display:'flex' , flexDirection: 'row' , gap: '10px' , alignItems: 'center'}}>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="body2">{body}</Typography>
      </MenuItem>
    </NavLink>
  ) : (
    <MenuItem onClick={handleClose} sx={{display:'flex'  , flexDirection: 'row' , gap: '10px' , alignItems: 'center'}}>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="body2">{body}</Typography>
    </MenuItem>
  )
)}

        <Divider />
      {program.map((p) => (
        <>
        <MenuItem sx={{display:'flex'  , flexDirection: 'column'  , alignItems: 'center'}}>
          <Typography variant="subtitle1">{p.courseName} Program Certificate Activated</Typography>
          <Typography variant="caption">Click To Download</Typography>
          <IconButton onClick={() => handleDownload(p.programCertificate.link , p.courseName)}>
            <DownloadForOfflineIcon/>
          </IconButton>
        </MenuItem>
        <Divider />
      </>
      ))}
      <Divider />
      {workex.map((p) => (
        <>
        <MenuItem sx={{display:'flex'  , flexDirection: 'column'  , alignItems: 'center'}}>
          <Typography variant="subtitle1">{p.courseName} Work Ex Certificate Activated</Typography>
          <Typography variant="caption">Click To Download</Typography>
          <IconButton onClick={() => handleDownload(p.programCertificate.link , p.courseName)}>
            <DownloadForOfflineIcon/>
          </IconButton>
        </MenuItem>
        <Divider />
      </>
      ))}
      </Menu>
    </React.Fragment>
  );
}
