import React , {useState , useEffect} from 'react';
import { FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa';
import './profile.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton, TextField, useMediaQuery , Avatar , Box , CircularProgress} from '@mui/material';
import axios from 'axios';
import { EDIT_PROFILE } from '../../../api/profile';
import { USER_REFFERAL } from '../../../api/vsc';
import CloseIcon from '@mui/icons-material/Close';
import Footer from '../../Footer/Footer';
import {useNavigate} from 'react-router-dom';
import CircularProgressWithLabel from './CircularProgressWithLabel';
const Profile = () => {
  const isMobile = useMediaQuery('(max-width:600px)')
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [editMode , setEditMode] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState(window.localStorage.email);
  const [location, setLocation] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [bio, setBio] = useState('');
  const [hobbies, setHobbies] = useState('');
  // Professional Details
  const [currentPosition, setCurrentPosition] = useState('');
  const [highestEducation, setHighestEducation] = useState('');
  const [skills, setSkills] = useState('');
  const [certifications, setCertifications] = useState('');
  const [xUrl , setXUrl] = useState('');
  const [linkedInUrl , setLinkedInUrl] = useState('');
  const [githubUrl , setGithubUrl] = useState('');
  const [points , setPoints] = useState(0);
  const [nBadges , setNBadges] = useState(0);
  const [refferal , setRefferals] = useState(0);
  const [profileImg , setProfileImg] = useState('');
  const [courses , setCourses] = useState(0);
  const [file , setFile] = useState(null);
  const n = window.localStorage.getItem("name");

  const [edited , setEdited] = useState(false);
  useEffect(() => {
    axios.get(USER_REFFERAL+window.localStorage.id)
    .then((res) => {
      setName(res.data.data.name);
      setEmail(res.data.data.email);
      setLocation(res.data.data.location);
      setDateOfBirth(res.data.data.dateOfBirth);
      setBio(res.data.data.bio);
      setHobbies(res.data.data.hobbies);
      setCurrentPosition(res.data.data.currentPosition);
      setHighestEducation(res.data.data.highestEducation);
      setSkills(res.data.data.skills);
      setCertifications(res.data.data.certifications);
      setXUrl(res.data.data.xUrl);
      setLinkedInUrl(res.data.data.linkedInUrl);
      setGithubUrl(res.data.data.githubUrl);
      setPoints(res.data.data.markoknowPoints);
      setNBadges(res.data.data.completedBadges.length);
      setRefferals(res.data.data.referredEmails.length);
      setProfileImg(res.data.data.profileImg);
      setCompletionPercentage(res.data.data.completionPercentage);
      setCourses(res.data.data.courseDetails.length)
      setEdited(false);
    })
  } , [edited])
  const styles = buildStyles({
    textColor: '#333',
    pathColor: '#6a58c6',
  });
  const handleSave = () => {
    let data = new FormData();
data.append('name', name);
data.append('email', email);
data.append('location', location);
data.append('dateOfBirth', dateOfBirth);
data.append('bio', bio);
data.append('hobbies', hobbies);
data.append('currentPosition', currentPosition);
data.append('highestEducation', highestEducation);
data.append('skills', skills);
data.append('certifications', certifications);
data.append('xUrl', xUrl);
data.append('linkedInUrl', linkedInUrl);
data.append('githubUrl',  githubUrl);
data.append('profileImg' , file);


    axios
    .patch(EDIT_PROFILE+window.localStorage.id , data)
    .then(res => {
      setEdited(true);
    })
    .catch(err => {
    })



  }
  const handleImageChange = (e) => {
    setFile(e.target.files[0]);
    setProfileImg(URL.createObjectURL(e.target.files[0]));
}

  return (
    <>
    <div className="profile-container" >
    {editMode&&<div style={{display:'flex' , justifyContent:'center' , alignItems: 'center' , marginBottom:'20px'}}>
         <Button variant='contained' style={{background:'#6a58c6' }} onClick={() =>{setEditMode(false); handleSave(); setEdited(true);}}>Save</Button>
         <IconButton onClick={()=> setEditMode(false)}>
         <CloseIcon style={{color:'#000'}}/>
         </IconButton>
     </div>}
   <div className="profile-pic-row">
     <div className="profile-image" >
      {profileImg && profileImg!==""? (
       <img
         src={profileImg}
         alt="Profile"
         style={{ borderRadius: '50%' }}
       />
       ): (
       <Avatar sx={{ width: "120px", height: "120px" , fontSize:'32px' }}>{name[0]}</Avatar>
      )}  

     </div>
      {editMode&&
          <Button
                variant="contained"
                component="label"
                style={{backgroundColor:'#6A58C6' , margin:'10px'}}
                
                >
               Upload
                <input
                type="file"
                hidden
                onChange={handleImageChange}
                accept = '.png , .jpg , .jpeg'
                />
                </Button>}
      <div className="profile-details" >
        {editMode?
        (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <TextField
                id="name"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ background: '#fff', height: '50', margin: '1%' }}
                
              /><br />
              <TextField
                id="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ background: '#fff', height: '50', margin: '1%' }}
                disabled
              /><br />
              <TextField
                id="location"
                label="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                style={{ background: '#fff', height: '50', margin: '1%' }}
              /><br />
            </div>
        )
        :(
          <>
        <h2 style={{color: '#fff' , textAlign:isMobile?'center':'right'}}>{name}</h2>
        <p  style={{color: '#fff', textAlign:isMobile?'center':'right'}}>Email: {email}</p>
        <p  style={{color: '#fff', textAlign:isMobile?'center':'right'}}>Location: {location}</p>
        </>) }
        <div className="social-icons">
        {editMode ? (
          <div style={{display:'flex' , flexDirection:isMobile&&'column' , alignItems:'center'}}>
          <TextField
                id="dateOfBirth"
                label="Twitter Url"
                value={xUrl}
                onChange={(e) => setXUrl(e.target.value)}
                style={{ background: '#fff', height: '50', margin: '1%' }}
              />
              <TextField
                id="dateOfBirth"
                label="LinkedIn Url"
                value={linkedInUrl}
                onChange={(e) => setLinkedInUrl(e.target.value)}
                style={{ background: '#fff', height: '50', margin: '1%' }}
              />
              <TextField
                id="dateOfBirth"
                label="Github Url"
                value={githubUrl}
                onChange={(e) => setGithubUrl(e.target.value)}
                style={{ background: '#fff', height: '50', margin: '1%' }}
              />
          </div>
        ): (
          <>
          <a onClick={()=>setEditMode(!editMode)}>
          <EditIcon /> 
          </a>
          {xUrl  && <a href={xUrl} target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>}
          
          {linkedInUrl && <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>}
          {githubUrl &&<a href={githubUrl} target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </a>}
          </>
        )}
         
        </div>
      </div>
      </div>
      <div className="details-section">
        <div className="personal-details">
          <h3>Personal Details</h3>
          <div className="basic-information">
            <h4>Basic Information</h4>
            {editMode ? (
              <TextField
                id="dateOfBirth"
                label="Date Of Birth"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                style={{ background: '#fff', height: '50', margin: '1%' }}
              />
            ) : (
              <p>Date of Birth: {dateOfBirth}</p>
            )}
          </div>
          <div className="personal-summary">
            <h4>Personal Summary</h4>
            {editMode ? (
              <>
                <TextField
                  id="bio"
                  label="Bio"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  style={{ background: '#fff', height: '50', margin: '1%' }}
                />
                <TextField
                  id="hobbies"
                  label="Hobbies"
                  value={hobbies}
                  onChange={(e) => setHobbies(e.target.value)}
                  style={{ background: '#fff', height: '50', margin: '1%' }}
                />
              </>
            ) : (
              <>
                <p>{bio}</p>
                <p>{hobbies}</p>
              </>
            )}
            </div>
        </div>
        <div className="statistics-section">
          <h3>Points Earned</h3>
          <div className="stat-boxes">
            <div className="stat-box">
              <strong style={{color: '#000' , fontFamily:'Poppins'}}>{nBadges}</strong>
              <p>Badges Earned</p>
            </div>
            <div className="stat-box">
              <strong style={{color: '#000', fontFamily:'Poppins'}}>0</strong>
              <p>Courses Bought</p>
            </div>
            </div>
          <div className="stat-boxes">
            <div className="stat-box">
              <strong style={{color: '#000', fontFamily:'Poppins'}}>{refferal}</strong>
              <p>Referrals Made</p>
            </div>
            <div className="stat-box">
              <strong style={{color: '#000', fontFamily:'Poppins'}}>{points}</strong>
              <p>Points Earned</p>
            </div>
          </div>
        </div>
        
      </div>
      <div className="details-section">

      <div className="professional-details">
          <h3>Professional Details</h3>
          <div className="employment-history">
            <h4>Employment History</h4>
            {editMode ? (
              <TextField
                id="currentPosition"
                label="Current Position"
                value={currentPosition}
                onChange={(e) => setCurrentPosition(e.target.value)}
                style={{ background: '#fff', height: '50', margin: '1%', width: '100%' }}
              />
            ) : (
              <p>Current Position: {currentPosition}</p>
            )}
          </div>
          <div className="education">
            <h4>Education</h4>
            {editMode ? (
              <TextField
                id="highestEducation"
                label="Highest Education"
                value={highestEducation}
                onChange={(e) => setHighestEducation(e.target.value)}
                style={{ background: '#fff', height: '50', margin: '1%', width: '100%' }}
              />
            ) : (
              <p>Highest Education: {highestEducation}</p>
            )}
          </div>
          <div className="skills-and-expertise">
            <h4>Skills and Expertise</h4>
            {editMode ? (
              <TextField
                id="skills"
                label="Skills"
                value={skills}
                onChange={(e) => setSkills(e.target.value)}
                style={{ background: '#fff', height: '50', margin: '1%', width: '100%' }}
              />
            ) : (
              <p>List of skills: {skills}</p>
            )}
          </div>
          <div className="certifications-and-awards">
            <h4>Certifications and Awards</h4>
            {editMode ? (
              <TextField
                id="certifications"
                label="Certifications"
                value={certifications}
                onChange={(e) => setCertifications(e.target.value)}
                style={{ background: '#fff', height: '50', margin: '1%', width: '100%' }}
              />
            ) : (
              <p>Professional certifications: {certifications}</p>
            )}
          </div>
          
        </div>
        <div className="progress-indicator">
          <h3>Profile Completion</h3>
          <div className="progressbar-container">
            <CircularProgressWithLabel
              value={completionPercentage}
              textAlign="center"
            />
          </div>
          <h4 style={{textAlign:'center'}}>You Have Completed {completionPercentage} of Profile</h4>
        </div>
        </div>
        {/* <div style={{marginTop:'30px'}}>
        <Refferal/>
        </div> */}
    </div>
    <Box sx={{marginTop:'30px',width: '100%'}}>
    
    <Footer />

    </Box>
    </>
  );
};

export default Profile;
