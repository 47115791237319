const role = window.localStorage.getItem("role");

export const MenuItems = role === "org"
  ? [
      {
        title: "View Demo",
        url: "/videodemo",
        cName: "nav-links",
      },
    ]
  : [
      {
        title: "Corporate Trainings",
        url: "/courses",
        cName: "nav-links",
      },
      {
        title: "Courses",
        url: "/courses",
        cName: "nav-links",
      },
      {
        title: "Learn Space",
        url: "/",
        cName: "nav-links",
      },
      {
        title: "Become A Trainee",
        url: "/mentor",
        cName: "nav-links",
      },
      {
        title: "Hire with VSC",
        url: "/hire",
        cName: "nav-links",
      },
    ];
