import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from "@mui/material/Avatar";
import { useNavigate } from 'react-router-dom';

export default function BasicMenu({profileImg , n , setUrl}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value) => {
    setUrl(value);
    setAnchorEl(null);
  };
  const logoutHandler = () => {
    setAnchorEl(null);
    window.localStorage.clear();
    navigate("/" , {state : {from : 'navbar'}});
    window.location.reload();
    window.location.reload();
  };
  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {profileImg? (
                        <Avatar sx={{ width: 32, height: 32 }} src={profileImg} />
                    ):
                    (<Avatar sx={{ width: 32, height: 32 }}>{n?.[0]}</Avatar>)}
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={(e)=>handleClose('courses')}>My Courses</MenuItem>
        <MenuItem onClick={(e)=>handleClose('earnedcertificates')}>My Certificates</MenuItem>
        <MenuItem onClick={(e)=>handleClose('redeem')}>My Points And Rewards</MenuItem>
        <MenuItem onClick={(e) => logoutHandler()}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
