import {
    Box,
    Tab,
    Tabs,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Link,
} from '@mui/material';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const AllStudents = () => {
    const location = useLocation();
    const { swotPeople } = location.state || { swotPeople: [] }; // Fallback in case no state is passed

    // State management
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);

    // Handle Tab Change
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // Filtered data based on search and booking status
    const filteredData = swotPeople.filter(student => {
        const nameMatch = student.name?.toLowerCase().includes(searchTerm.toLowerCase());
        const isBooked = !!student.bookingDate; // Check if bookingDate exists

        if (selectedTab === 0) {
            return nameMatch && isBooked; // Booked Students
        }
        if (selectedTab === 1) {
            return nameMatch && !isBooked; // Unbooked Students
        }
        return nameMatch;
    });

    return (
        <Box sx={{ marginTop: '120px', padding: '0 20px' }}>

            <Typography variant='h4' textAlign={'center'} mb={10}>
                All Students 
            </Typography>
            {/* Search Bar */}
            <TextField
                label="Search Students"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: '20px' , width: '30%' }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            {/* Tabs: Booked and Unbooked Students */}
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="student status tabs">
                <Tab label="Booked" />
                <Tab label="Unbooked" />
            </Tabs>

            {/* Dynamic Heading */}
            <Typography variant="h5" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
                {selectedTab === 0 ? 'Booked Students List' : 'Unbooked Students List'}
            </Typography>

            {/* Table to Display Data */}
            <TableContainer sx={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sl.</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>WhatsApp Number</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Strength</TableCell>
                            <TableCell>Weakness</TableCell>
                            <TableCell>Opportunity</TableCell>
                            <TableCell>Threat</TableCell>
                            <TableCell>Feedback</TableCell>
                            <TableCell>Screenshot</TableCell>
                            <TableCell>Resume</TableCell>
                            <TableCell>Rating</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.length > 0 ? (
                            filteredData.map((student, index) => (
                                <TableRow key={student.studentCode || index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{student.name || 'N/A'}</TableCell>
                                    <TableCell>{student.email || 'N/A'}</TableCell>
                                    <TableCell>{student.whatsappNumber || 'N/A'}</TableCell>
                                    <TableCell>{student.bookingDate || 'N/A'}</TableCell>
                                    <TableCell>{student.status || 'N/A'}</TableCell>
                                    <TableCell>{student.strength || 'N/A'}</TableCell>
                                    <TableCell>{student.weakness || 'N/A'}</TableCell>
                                    <TableCell>{student.opportunity || 'N/A'}</TableCell>
                                    <TableCell>{student.threat || 'N/A'}</TableCell>
                                    <TableCell>{student.feedback || 'N/A'}</TableCell>
                                    <TableCell>
                                        {student.screenshot ? (
                                            <Link href={student.screenshot} target="_blank" rel="noopener">
                                                View Screenshot
                                            </Link>
                                        ) : (
                                            'N/A'
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {student.resume ? (
                                            <Link href={student.resume} target="_blank" rel="noopener">
                                                View Resume
                                            </Link>
                                        ) : (
                                            'N/A'
                                        )}
                                    </TableCell>
                                    <TableCell>{student.ratingOverall || 'N/A'}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    No students found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default AllStudents;
