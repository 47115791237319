import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

export function sanitizeAndParseText(html) {
  if (!html) return '';
  
  // Sanitize the HTML content to remove malicious scripts
  const cleanHTML = DOMPurify.sanitize(html);
  
  // Parse the sanitized HTML
  const parsedContent = parse(cleanHTML);
  
  // If the parsed content is a React element, extract its text content
  if (isReactElement(parsedContent)) {
    return extractTextFromReactElement(parsedContent);
  }
  
  // If it's already a string, return it
  if (typeof parsedContent === 'string') {
    return parsedContent;
  }
  
  // If it's an array (multiple elements), join their text content
  if (Array.isArray(parsedContent)) {
    return parsedContent.map(element => 
      isReactElement(element) ? extractTextFromReactElement(element) : String(element)
    ).join(' ');
  }
  
  // Fallback: convert to string
  return String(parsedContent);
}

function isReactElement(element) {
  return element && typeof element === 'object' && '$$typeof' in element;
}

function extractTextFromReactElement(element) {
  if (typeof element.props.children === 'string') {
    return element.props.children;
  }
  if (Array.isArray(element.props.children)) {
    return element.props.children.map(child => 
      isReactElement(child) ? extractTextFromReactElement(child) : String(child)
    ).join(' ');
  }
  return '';
}
