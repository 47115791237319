import React from 'react';
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';

import { sanitizeAndParseText }  from './textSanitize';


// Rest of the styles remain the same
const styles = StyleSheet.create({
    page: {
      padding: 24,
      backgroundColor: '#ffffff'
    },
    header: {
      flexDirection: 'row',
      marginBottom: 30,
      gap: 16
    },
    profileImage: {
      width: 80,
      height: 80,
      marginRight: 16
    },
    studentInfo: {
      flex: 1
    },
    infoRow: {
      marginBottom: 4
    },
    text: {
      fontSize: 12,
      color: '#333333'
    },
    boldText: {
      fontSize: 12,
      fontWeight: 'bold',
      color: '#333333'
    },
    ratingSection: {
      marginBottom: 20
    },
    ratingRow: {
      marginBottom: 24
    },
    ratingHeader: {
      fontSize: 14,
      fontWeight: 'bold',
      marginBottom: 4,
      color: '#333333'
    },
    ratingDescription: {
      fontSize: 11,
      color: '#666666',
      marginBottom: 8
    },
    ratingBarContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 12
    },
    ratingBarWrapper: {
      flex: 1
    },
    ratingBar: {
      height: 8,
      backgroundColor: '#ffffff',
      borderRadius: 4
    },
    ratingFill: {
      height: '100%',
      backgroundImage: 'linear-gradient(90deg, #ffc01f, #afffd6)',
      borderRadius: 4
    },
    ratingScale: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 4
    },
    scaleNumber: {
      fontSize: 10,
      color: '#666666'
    },
    ratingCircle: {
      width: 36,
      height: 36,
      backgroundColor: '#FFB84C',
      borderRadius: 18,
      justifyContent: 'center',
      alignItems: 'center'
    },
    ratingValue: {
      color: '#ffffff',
      fontSize: 12,
      fontWeight: 'bold'
    },
    feedbackGrid: {
      flexDirection: 'row',
      gap: 16,
      marginBottom: 16
    },
    feedbackCard: {
      flex: 1,
      backgroundColor: '#7affc9',
      padding: 12,
      borderRadius: 4
    },
    cardHeader: {
      backgroundColor: '#FFB84C',
      padding: 8,
      marginBottom: 8,
      marginLeft: -12,
      marginRight: -12,
      marginTop: -12
    },
    cardHeaderText: {
      color: '#ffffff',
      fontSize: 12,
      fontWeight: 'bold'
    },
    feedbackContent: {
      fontSize: 11,
      color: '#333333'
    }
  });
const StudentReportPDF = ({ student, labels }) => {
  // Convert HTML content to plain text
  const sanitizedStrength = sanitizeAndParseText(student?.strength);
  const sanitizedWeakness = sanitizeAndParseText(student?.weakness);
  const sanitizedFeedback = sanitizeAndParseText(student?.feedback);
  const sanitizedSuggestion = sanitizeAndParseText(student?.suggestion);

  console.log(sanitizedFeedback , sanitizedStrength , sanitizedSuggestion , sanitizedWeakness)

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* First page content remains the same */}
        <View style={styles.header}>
          <Image 
            style={styles.profileImage}
            src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
          />
          <View style={styles.studentInfo}>
            <View style={styles.infoRow}>
              <Text style={styles.text}>Name : {student?.name}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.text}>Roll Number : {student?.studentCode}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.text}>College : {student?.orgCode}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.text}>Date : {student?.bookingDate}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.text}>Time : {student?.time}</Text>
            </View>
          </View>
          <View>
            <Text style={styles.text}>Top 10% : {student?.topTenPercent ? 'Yes' : 'No'}</Text>
            <Text style={styles.text}>Rating : {student?.ratingOverall}</Text>
          </View>
        </View>

        <View style={styles.ratingSection}>
          {labels.map((label) => (
            <View key={label.label} style={styles.ratingRow}>
              <Text style={styles.ratingHeader}>{label.label} :</Text>
              <Text style={styles.ratingDescription}>
                Based on how accurately and relevantly you conveyed your {label.label.toLowerCase()}.
              </Text>
              <View style={styles.ratingBarContainer}>
                <View style={styles.ratingBarWrapper}>
                  <View style={styles.ratingBar}>
                    <View style={[styles.ratingFill, { width: `${label.value}%`  }]} />
                  </View>
                  <View style={styles.ratingScale}>
                    {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((num) => (
                      <Text key={num} style={[styles.scaleNumber, 
                        num === label.average && { backgroundColor: '#7affc9', padding: 2, borderRadius: 2 }
                      ]}>
                        {num}
                      </Text>
                    ))}
                  </View>
                </View>
                <View style={styles.ratingCircle}>
                  <Text style={styles.ratingValue}>{label.value}</Text>
                </View>
              </View>
            </View>
          ))}
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.feedbackGrid}>
          <View style={styles.feedbackCard}>
            <View style={styles.cardHeader}>
              <Text style={styles.cardHeaderText}>Strength</Text>
            </View>
            <Text style={styles.feedbackContent}>{sanitizedStrength}</Text>
          </View>
          <View style={styles.feedbackCard}>
            <View style={styles.cardHeader}>
              <Text style={styles.cardHeaderText}>Weakness</Text>
            </View>
            <Text style={styles.feedbackContent}>{sanitizedWeakness}</Text>
          </View>
        </View>

        <View style={styles.feedbackCard}>
          <View style={styles.cardHeader}>
            <Text style={styles.cardHeaderText}>Feedback</Text>
          </View>
          <Text style={styles.feedbackContent}>{sanitizedFeedback}</Text>
        </View>

        <View style={styles.feedbackCard}>
          <View style={styles.cardHeader}>
            <Text style={styles.cardHeaderText}>Suggestion</Text>
          </View>
          <Text style={styles.feedbackContent}>{sanitizedSuggestion}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default StudentReportPDF;

