import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../stylesheets/Kickstart_details.css";
import course_1 from "../NewVirtualCampusStartup/Images/mvp.png";
import course_2 from "../NewVirtualCampusStartup/Images/gtm.png";
import course_3 from "../NewVirtualCampusStartup/Images/bmc.png";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Footer from "../Footer/Footer";
import {Link , useNavigate} from 'react-router-dom';
import { Button, Typography , IconButton , Box , Modal } from "@mui/material";
import {useMediaQuery} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { USER_ACCESS , MARKOKNOW_CHECKOUT } from "../../api/vsc";
import coming from './Images/comingsoon.png';
import { CART_UPDATE,CART_REMOVE,CART_UPDATE_ALL, CART_REMOVE_THREE } from "../../api/payment";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const comingSoonImageStyle = {
  position: "absolute",
  top: "-10px",
  left: "-20px",
  width: "120px", // Adjust size as needed
  height: "120px", // Adjust size as needed
  zIndex: 2,
  transform: "rotate(-0deg)", // Optional: adds a slight rotation
};


const CourseWithPayment = ({ isAuth, from  }) => {
   const navigate = useNavigate();
  const { MARKOKNOW_KICKSTART, USER , GET_USER , GET_COURSES } = require("../../api/vsc");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [course1, setCourse1] = useState("");
  const [course2, setCourse2] = useState("");
  const [course3, setCourse3] = useState("");
  const [course1P ,setCourse1P ] = useState(0);
  const [course2P ,setCourse2P ] = useState(0);
  const [course3P ,setCourse3P ] = useState(0);
  const [selectedField, setSelectedField] = useState("All");
  const isMobile = useMediaQuery('(max-width:1100px)');
  const isLMobile = useMediaQuery('(max-width:1300px)');
  const [refresh , setRefresh] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [paid , setPaid] = useState(false);
  const [openE, setOpenE] = React.useState(false);
  const handleOpen = () => setOpenE(true);
  const handleCloseE = () => setOpenE(false);
  const [count , setCount] = React.useState(0);
  const [activateDate , setActivateDate] = React.useState('');
  const [activateTime , setActivateTime] = React.useState('');
  const [cA , setCa] = React.useState(false);
  const [op, setOp] = React.useState(false);
  const [bannerOpen, setBannerOpen] = React.useState(false);
  const [courses , setCourses] = React.useState([]);

  const handleCl = () => {
    setOp(false);
  }
  React.useEffect(() => {
    axios.get(GET_COURSES)
    .then(res => {
      const sortedCourses = res.data.data.sort((a, b) => {
        const priorityA = a.priority || 1000; 
        const priorityB = b.priority || 1000; 
        return priorityA - priorityB;
      });
        setCourses(sortedCourses);
    })
    .catch(err => {
    })
  },[]);
  
  const closeBanner = () => {
    setBannerOpen(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: !isMobile?400:'70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    axios
      .get(USER_ACCESS + window.localStorage.id)
      .then((res) => {
        setPaid(res.data.access);
      })
      .catch((err) => {});
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb08d372d0fde41877df63")
      .then(res => {
        //  setCourse1P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb15b672d0fde41877e193")
      .then(res => {
         setCourse2P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb105b72d0fde41877e101")
      .then(res => {
         setCourse3P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
  }, []);
  const handleClick = () => {
    setOpen(true);
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setData(response.data);
        setCourse1(response.data.course1 || ""); // Set the initial state of course1
        setCourse2(response.data.course2 || ""); // Set the initial state of course2
        setCourse3(response.data.course3 || ""); // Set the initial state of course3
        if(response.data.course1==1) setCount(3);
        setCourse1P(((response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63")?.videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63")?.pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").total)*100);
        setCourse2P(((response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193")?.videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193")?.pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").total)*100);
        setCourse3P(((response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101")?.videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101")?.pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").total)*100);
        var currentDate = new Date();
        const course = response.data.certificateActivate.find(
          (course) => course.courseId === "64fb08d372d0fde41877df63"
        ).dateOfActivation;
        const activate = new Date(course)
        var array = activate.toLocaleString().split(',');
        setActivateDate(array[0]);
        setActivateTime(array[1]);
        if (currentDate > activate) {
          setCa(true);
        }
        setRefresh(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [refresh]);
  const refe = () => {
    setRefresh(true);
  }
  useEffect(() => {
  }, [data]);
  const user = localStorage.getItem("email");

  const addCourse1 = (refresh , price,courseName) => {
    if(refresh === "64fb08d372d0fde41877df63"||refresh === "64fb15b672d0fde41877e193"||refresh === "64fb105b72d0fde41877e101" ){
      axios.post(CART_UPDATE_ALL+"64fb08d372d0fde41877df63"+"/64fb15b672d0fde41877e193/"+"64fb105b72d0fde41877e101/"+window.localStorage.id, [
        {
          courseId: "64fb08d372d0fde41877df63",
          price: 0,
          courseName: "Minimum Viable Product",
        },
        {
          courseId: "64fb15b672d0fde41877e193",
          price: 0,
          courseName: "Go To Market",
        },
        {
          courseId: "64fb105b72d0fde41877e101",
          price: 350,
          courseName: "Business Model Planning",
        },
      ]).then((res) => setData(res.data.data)).catch((err) =>{});
    }
    else {
      axios.post(CART_UPDATE+refresh+"/"+window.localStorage.id ,{price:price,courseName: courseName} ).then(res => setData(res.data.data)).catch(err=> {});
    }
    setRefresh(true);

  };
  const removeCourse = (refresh , price, courseName) => {
    if(refresh === "64fb08d372d0fde41877df63"||refresh === "64fb15b672d0fde41877e193"||refresh === "64fb105b72d0fde41877e101" ){
      axios.post(CART_REMOVE_THREE+"64fb105b72d0fde41877e101/"+window.localStorage.id).then((res) => setData(res.data.data)).catch((err) => {});
    }
    else{
      axios.patch(CART_REMOVE+refresh+"/"+window.localStorage.id ,{courseName:courseName}).then(res => {}).catch(err=> {});
    }
    setRefresh(true);
  };
  const isSpecificCourse = ['64fb08d372d0fde41877df63', '64fb15b672d0fde41877e193', '64fb105b72d0fde41877e101'];
  return (
    <div>
      <div className="course_landing" style={{marginBottom:'50px' , marginTop: from && '20px' , width: from &&'96vw'}}>
      <h2 id="our-courses-course" style={{textAlign:'center'}}>{!from ? 'Our' : 'All'} Courses</h2>

        {!from  && (
          <div style={{display:'flex' , justifyContent:'center' , flexDirection:isMobile&&'column'
        }}>
          <div style={{display:'flex' , flexDirection:'row' , justifyContent:'center'}}>
           <Button 
            variant="contained" 
            style={{background:selectedField==='All'?'#000':'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            onClick={() => setSelectedField('All')}
            >All</Button>
            <Button 
            variant="contained" 
            style={{background:selectedField==='startup'?'#000':'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            onClick={() => setSelectedField('startup')}
            >Startup
            </Button>
            <Button 
            variant="contained" 
            style={{background:selectedField==='product'?'#000':'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            onClick={() => setSelectedField('product')}
            >Product</Button>
            </div>
            <div style={{display:'flex' , flexDirection:'row' , justifyContent:'center'}}>
            <Button 
            variant="contained" 
            style={{background:selectedField==='marketing'?'#000':'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            onClick={() => setSelectedField('marketing')}
            >Marketing</Button>
            <Button 
            variant="contained" 
            style={{background:'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            >Analytics</Button>
            <Button 
            variant="contained" 
            style={{background:'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            >Strategy</Button>
            </div>
            <div style={{display:'flex' , flexDirection:'row', justifyContent:'center'}}>
            <Button 
            variant="contained" 
            style={{background:'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            >Fundraising</Button>
            <Button 
            variant="contained" 
            style={{background:'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            >Revenue</Button>
            </div>
          </div>
        )}  
        <div className="kickstart_courses_box" style={{  borderRadius:'10px' ,  width: isMobile&&'100%'  ,  boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',}}>
          <div className="level_courses" style={{display: 'flex',justifyContent:'space-evenly' , gap: '5px', alignItems: 'center', flexWrap: 'wrap' }}>
          {(selectedField === 'startup'||selectedField === 'product'||selectedField === 'marketing'|| selectedField === 'All')  && (
            courses.map((course) => (
              <div className='course_1' style={{marginRight: isMobile&&'0px',marginBottom: '20px', boxSizing: 'border-box' , height: '100%' , position:'relative'}}>
                {course.comingsoon && (
                  <img 
                    src={coming}
                    alt="Coming Soon"
                    style={comingSoonImageStyle}
                  />
                )}
              <img
                src={course.coursePreview}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "300px",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
                alt='Course 1'
              />
              <div 
               style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                   
                  }}>
                  <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                    textAlign:'center'
                  }}
                  >
                  {course.forWhom}
                  </p>
              </div>
              <div className="course_data">
               
                {/* <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  {course.courseName}
                </p> */}
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  {course.courseName}
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    {course.courseName}
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    {course.learn}
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>{" "}
                    Outcome: {course.outcomes}
                  </p>
                  {/* <LinearProgressWithLabel value={course1P} color={"#000"}/>
                  <p style={{fontWeight:'900'}}>{Math.round(course1P)}% Completed</p> */}

                </div>
              </div>
                

              <div className={`course_1_button`}>
  {/* <a href="/program/markoknow-kickstart/landing-page">
    <div className="details">View Details</div>
  </a> */}
  {
    course._id === "6692371aa38512e3cedfe57e" ? (
      <button style={{ backgroundColor: '#6E71CC', color: '#fff' }} disabled>
        Open Individual Courses
      </button>
    ) :
    isSpecificCourse.includes(course._id) && data.courses && data.courses.length > 0 && data.courses.find((course) => (
      course === "64fb08d372d0fde41877df63" || course === "64fb15b672d0fde41877e193" || course === "64fb105b72d0fde41877e101"
    )) ? (
      <a href={`/program/videoplayer/${course._id}`}>
        <button style={{ backgroundColor: '#6E71CC', color: '#fff' }}>Open</button>
      </a>
    ) :
    !isSpecificCourse.includes(course._id) && data.courses && data.courses.length > 0 && data.courses.find((cours) => course._id === cours) ? (
      <a href={`/program/videoplayer/${course._id}`}>
        <button style={{ backgroundColor: '#6E71CC', color: '#fff' }}>Open</button>
      </a>
    ) :
    isAuth ? (
      data.cart && !data.cart.find((cart) => course._id === cart.courseId) ? (
        <button
          onClick={(e) => addCourse1(course._id, course.price, course.courseName)}
          style={{ backgroundColor: "#6a58c6" }}
        >
          Add Course
        </button>
      ) : (
        <button
          onClick={(e) => removeCourse(course._id, course.price, course.courseName)}
          style={{ backgroundColor: "green" }}
        >
          Remove Course
        </button>
      )
    ) : (
      <button
        onClick={(e) => {
          navigate('/login', { state: { redirect: '/courses' } });
        }}
        style={{ backgroundColor: "#6a58c6" }}
      >
        Read More
      </button>
    )
  }
</div>
            </div>
            ))
          )}
            
          </div>
        </div>
      </div>
      {course1==='1'&&<Snackbar open={op} autoHideDuration={6000} onClose={handleCl} style={{ zIndex:'999999' }}>
        <Alert onClose={handleCl} severity="info" sx={{ width: '100%' , display:isMobile&&'none'  }}>
          Click To Proceed To Checkout <a href={MARKOKNOW_CHECKOUT+"64fb08d372d0fde41877df63/"+window.localStorage.id}>Proceed To Checkout</a>
        </Alert>
      </Snackbar>}
      {/* <Snackbar open={open&&!isMobile} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%'  }}>
          {course1==='1'&& 'All the three courses are added together. As its a bundle'}
          {course1==='0'&& 'All the three courses are removed together. As its a bundle'}
        </Alert> 
      </Snackbar> */}
      <Modal
        open={openE}
        onClose={handleCloseE}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{width:isMobile?'70%':400}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          {!cA ?'Activate The Certificate' :'Complete The Course'}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {!cA ? `The Certificate Would Be Activated After ${activateTime} on ${activateDate}`:'To Generate Your Certificate First Complete The Course.'}
          </Typography>
        </Box>
      </Modal>
      <div>
      
      </div>
      <Footer />
    </div>
  );
};

export default CourseWithPayment;
