import React, { useState, useEffect } from 'react';
import learn from './Images/learn.png';
import apply from './Images/apply.png';
import workex from './Images/workEx.png';
import { Typography , useMediaQuery } from '@mui/material';


const SideView = ({ selected }) => {
    const [imgUrl, setImgUrl] = useState(learn);       
    const isMobile = useMediaQuery('(max-width: 850px)');
    const [text, setText] = useState(
      `<h4 style={{width: selected === 'workex' || selected === 'apply' ? '40%' : '100%', flexWrap: 'break-word', textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Get sessions on practical training in recorded <u style={{textDecorationColor: '#FF5757', textDecorationThickness: '3px'}}>sessions and labs</u></h4>`
    );
    const [direction, setDirection] = useState('');
  
    useEffect(() => {
      if (selected === 'learn') {
        setImgUrl(learn);
        setText(`<h4 style={{width: selected === 'workex' || selected === 'apply' ? '40%' : '100%', flexWrap: 'break-word', textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Get sessions on practical training in recorded <u style={{textDecorationColor: '#FF5757', textDecorationThickness: '3px'}}>sessions and labs</u></h4>`);
        setDirection('column');
      }
      if (selected === 'apply') {
        setImgUrl(apply);
        setText(`<h4 style={{width: selected === 'workex' || selected === 'apply' ? '40%' : '100%', flexWrap: 'break-word', textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}><u style={{textDecorationColor: '#FF5757', textDecorationThickness: '3px'}}>Capstone Project</u> to complete on the topic to promote a project-based learning</h4>`);
        setDirection('row');
      }
      if (selected === 'workex') {
        setImgUrl(workex);
        setText(`<h4 style={{width: selected === 'workex' || selected === 'apply' ? (isMobile? '100%' : '20%' ): '100%', flexWrap: 'break-word', textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Gain Experience working with Partnered Startups to attain <u style={{textDecorationColor: '#FF5757', textDecorationThickness: '3px'}}>Industry Exposure</u></h4>`);
        setDirection('row-reverse');
      }
    }, [selected]);
  
    return (
      <div style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : direction  ,
        alignItems: 'center',
        justifyContent: selected !== 'workex' ? 'center' :'space-evenly'
      }}>
      <img src={imgUrl} alt="Learn" width={selected === 'workex' || selected === 'apply' ? "200" : "70%"} height="300" marginRight={selected === 'workex' && '10px'}/>
        <div style={{margin: '10px'}}  dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    );
  };
  
  export default SideView;
  