import React , {useRef , useEffect} from 'react';
import certificateImg from './3.png';
import signature from './signature.png';
import { useLocation , useParams , useNavigate} from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import html2canvas from 'html2canvas';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
import { POST_CERTIFICATE } from '../../../../api/certificate';
import { LinkedinShareButton , LinkedinIcon , WhatsappShareButton , WhatsappIcon} from 'react-share';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
const Certificate = () => {
  const [isAuth, setIsAuth] = React.useState(
    window.localStorage.getItem("isAuth") || false
  );
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const date = new Date();
  const now = date.toLocaleString();
  const myArray = now.split(',');
  const certificateRef = useRef(null); 
  const isMobile = useMediaQuery('(max-width:600px)');
  const uid = uuid();
  const cLink = 'https://www.virtualstartupcampus.com/viewcertificate/'+uid;
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClose = () => {
    const new_data = {
      userId: window.localStorage.id,
      courseId: params.id,
      courseName: 'Product To Model',
      serielNumber: uid,
      userName: window.localStorage.name,
      dateIssue: myArray[0]
  }
  axios
  .post(POST_CERTIFICATE , new_data)
  .then(res=> {
      // console.log(res);
  })
  .catch(err=> {
      console.log(err);
  })
    setOpen(false);
  };
  const certificateStyles = {
    backgroundImage: `url(${certificateImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize:'100% auto',
    width: isMobile?'95vw':'100%',
    height: isMobile ? '45vh' : 'auto',
    fontSize: isMobile ? '10px' : '24px', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
    textAlign: 'center',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    margin:'2%',
    border:'2px #000 solid'
  };

  const signatureStyles = {
    width: isMobile?'30%':'30%',
    height: 'auto',
    marginTop: '20px',
    
  };

  const underlineStyles = {
    marginTop:0,
    borderBottom: '2px solid #000',
    width: isMobile?'30%':'15%', 
  };

  const ceoNameStyles = {
    marginTop: '10px',
    fontWeight:'bold'
  };

  const ceoDesignationStyles = {
    fontSize: isMobile?'10px':'18px',
  };


  const downloadCertificate = async () => {
    const canvas = await html2canvas(certificateRef.current);
    const dataURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = `${window.localStorage.name}-${location?.state?.courseName}-certificate.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
    {open ? (
      <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {isAuth?'Your Certificate Is Ready Click To See':'First Login'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isAuth?'Congrats On Completing The Course':'Click Here To Login'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>
          isAuth?handleClose():navigate('/login')} autoFocus>
            {isAuth?'Certificate':'Login'}
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    ): (
      <div style={{ marginTop: '120px' , display:'flex' , flexDirection:!isMobile?'row':'column' }}>
      <div style={{width:'100%' , marginRight:'40px'}}>
      <div ref={certificateRef} style={certificateStyles}>
      {/* <div style={{width:'100%'}}> */}
        <p style={{fontWeight:'bold' ,}}>Markoknow</p>
        <h1 style={{fontSize:isMobile&&'15px'}}>
          CERTIFICATE
        </h1>

        <h3 style={{fontWeight:'initial',marginBottom:isMobile?'10px':'20px'}}>OF COMPLETION</h3>
        
        <p style={{fontSize:isMobile?'6px':'12px'}}>This is to certify that</p>
        <h3 style={{margin:0}}>{window.localStorage.name}</h3>
        <p style={{fontSize:isMobile?'6px':'12px'}}>Has Completed</p>
        <br />
        <h4>Product To Model</h4>
        {/* </div> */}
        <p style={{ width: isMobile?'40%':'40%' , fontSize:isMobile?'6px':'15px' , marginTop:'10px'}}>
          Skillset Achieved : Brainstorming, Problem Solving, Critical Thinking, Creative Visualization, Canva Designing, Entrepreneurship
        </p>
        <img
          
          src={signature}
          alt="CEO Signature"
          style={signatureStyles}
        />
        <span style={underlineStyles}></span>
        <p style={ceoNameStyles}>Kaushiki</p>
        <p style={ceoDesignationStyles}>Founder And CEO, Startup Virtual Campus</p>
        <p style={{marginTop:isMobile?'15px':'30px' , fontSize:isMobile?'5px':'10px'}}>Issued On: {myArray[0]}</p>
        <p style={{ fontSize:isMobile?'5px':'10px'}}>Seriel No.: {uid}</p>
      </div>
      </div>
      <div style={{display:'flex' , flexDirection:'column' , justifyContent:'flex-start' , marginTop:'20px'}}>
      <p>Link To View Certificate:</p>
      <div className="link">
                <input
                  type="text"
                  id="link"
                  name="link"
                  value={cLink}
                  className="link-input"
                />
      </div>
      <Button variant='contained' onClick={downloadCertificate} style={{ marginTop: isMobile?'15px':'30px' , backgroundColor:'#6A58C6' }}>
          Download Certificate
      </Button>
      <div style={{marginTop:'10px'}}>
      <LinkedinShareButton url={cLink}>
        <LinkedinIcon round={true}/>
      </LinkedinShareButton>
      <WhatsappShareButton url={cLink}>
        <WhatsappIcon round={true}/>
      </WhatsappShareButton>
      </div>
      </div>
    </div>
    )}
    </div>
  );
};

export default Certificate;
