import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import Axios from "axios";
import { GET_ANNOUNCEMENT } from "../../api/announcement";

function NotificationBar({ setClosed }) {
  const [notif, setNotif] = useState(true);
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");
  const [links, setLinks] = useState("https://virtualstartupcampus.com");

  useEffect(() => {
    Axios.get(GET_ANNOUNCEMENT)
      .then((response) => {
        if(response.data === "No documents found") {setNotif(false); setClosed(true); return <></>}
        const { body , title, link } = response.data[0] || {};
        setBody(body || ""); // Default to empty string if body is null/undefined
        setTitle(title || ""); // Default to empty string if title is null/undefined
        setLinks(link ?? "https://virtualstartupcampus.com"); // Default to "/" if link is null/undefined
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {(body && title && notif && links !== 'https://virtualstartupcampus.com') ? (
        <div
          style={{
            background: '#F0F0F0',
            backdropFilter: 'blur(15px)',
            marginBottom: "0px",
            paddingBottom: "8px",
            paddingTop: "8px",
            textAlign: "center",
            zIndex: '100000'
          }}
        >
          <div>

            <a
              href={links}
              target="_blank"
              rel="noopener noreferrer" // Ensures safe external link handling
              style={{ textDecoration: "none" }}
            >
              <span
                style={{
                  color: "#6A58C6",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  marginLeft: "10px"
                }}
              >
                {title}
              </span>
              {"  "}
              <span
                style={{
                  color: "#000",
                  textDecoration: "none",
                  fontWeight: "600"
                }}
              >
                {body}
              </span>
            </a>
            <CloseIcon
              style={{ float: "right", cursor: "pointer" }}
              onClick={() => {
                setNotif(false);
                setClosed(true);
              }}
            />
          </div>
        </div>
      ) : <div></div>}
    </>
  );
}

export default NotificationBar;
