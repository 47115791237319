import React, { useState, useRef, useEffect } from 'react';
import './PopupChat.css';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
import axios from 'axios';
import { SEND_EMAIL } from '../../api/popupchat';
import { categories } from './categories';

const PopupChat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(window.localStorage.name || '');
  const [email, setEmail] = useState(window.localStorage.email || '');
  const [whatsapp, setWhatsapp] = useState(window.localStorage.whatsapp || '');
  const [isNameEmailRequired, setIsNameEmailRequired] = useState(
    !window.localStorage.name || !window.localStorage.email || !window.localStorage.whatsapp
  );
  const [animateIcon, setAnimateIcon] = useState(false);
  const [currentStep, setCurrentStep] = useState('initial');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const chatRef = useRef(null);

  const isUserMessage = (index, message) => {
    // First two messages are always system messages
    if (index <= 1) return false;
    // User messages are odd-indexed after the first two
    return index % 2 === 1;
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setCurrentStep('initial');
      setMessages([`Hey ${window.localStorage.name || 'Guest'}`, "How can I help you today?"]);
    }
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      if (!name.trim() || !email.trim() || !whatsapp.trim()) {
        setMessages([...messages, "Please provide your name, email, and WhatsApp number to continue."]);
        setIsNameEmailRequired(true);
        return;
      }
      setIsLoading(true);
      axios
        .post(SEND_EMAIL, {
          name,
          email,
          whatsapp,
          chat: newMessage,
        })
        .then((res) => {
          if (res.data.message === 'Successful') {
            setShowMessage(true);
          }
          setMessages([...messages, newMessage, "Your message has been sent. We'll get back to you within 48 hours."]);
          setNewMessage('');
          setCurrentStep('initial');
        })
        .catch((err) => {
          console.error(err);
          setMessages([...messages, newMessage, "Sorry, there was an error sending your message. Please try again later."]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleClickOutside = (event) => {
    if (chatRef.current && !chatRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleNameEmailSubmit = () => {
    if (name.trim() && email.trim() && whatsapp.trim()) {
      window.localStorage.setItem('name', name);
      window.localStorage.setItem('email', email);
      window.localStorage.setItem('whatsapp', whatsapp);
      setMessages([`Hey ${name}`, "How can I help you today?"]);
      setIsNameEmailRequired(false);
      setCurrentStep('initial');
    }
  };

  useEffect(() => {
    setAnimateIcon(true);
    const timer = setTimeout(() => setAnimateIcon(false), 6000);
    return () => clearTimeout(timer);
  }, []);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setMessages([...messages, `You selected: ${category.title}`, "Please choose a question from the list below:"]);
    setCurrentStep('questions');
  };

  const handleQuestionSelect = (question) => {
    setSelectedQuestion(question);
    setMessages([...messages, `Q: ${question.question}`, `A: ${question.answer}`, "Did this answer solve your issue?"]);
    setCurrentStep('feedback');
  };

  const handleFeedback = (isSolved) => {
    if (isSolved) {
      setMessages([...messages, "Great! I'm glad I could help. Is there anything else you need assistance with?"]);
      setCurrentStep('initial');
    } else {
      setMessages([...messages, "I'm sorry the answer didn't solve your issue. Would you like to see the categories again or send a custom message?"]);
      setCurrentStep('unsolved');
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      case 'initial':
        return (
          <div className="render-content">
            <p>Please select a category:</p>
            <div className="grid-container">
              {categories.map((category, index) => (
                <button
                  key={index}
                  onClick={() => handleCategorySelect(category)}
                  className="grid-item"
                >
                  {category.title}
                </button>
              ))}
            </div>
            <button className="grid-item" onClick={() => setCurrentStep('custom')}>
              Other (Custom Message)
            </button>
          </div>
        );
      case 'questions':
        return (
          <div className="render-content">
            <p>Please select a question:</p>
            <div className="grid-container">
              {selectedCategory.items.map((item, index) => (
                <button
                  key={index}
                  onClick={() => handleQuestionSelect(item)}
                  className="grid-item"
                >
                  {item.question}
                </button>
              ))}
            </div>
            <button className="grid-item" onClick={() => setCurrentStep('initial')}>
              Back to Categories
            </button>
          </div>
        );
      case 'feedback':
        return (
          <div className="render-content">
            <p>Did this answer solve your issue?</p>
            <div className="grid-container">
              <button
                className="grid-item"
                onClick={() => handleFeedback(true)}
              >
                Yes, it solved my issue
              </button>
              <button
                className="grid-item"
                onClick={() => handleFeedback(false)}
              >
                No, I still need help
              </button>
            </div>
          </div>
        );
      case 'unsolved':
        return (
          <div className="render-content">
            <div className="grid-container">
              <button className="grid-item" onClick={() => setCurrentStep('initial')}>
                See categories again
              </button>
              <button className="grid-item" onClick={() => setCurrentStep('custom')}>
                Send a custom message
              </button>
            </div>
          </div>
        );
      case 'custom':
        return (
          <div className="input-container">
            <input
              type="text"
              value={newMessage}
              onChange={handleInputChange}
              placeholder="Type your message..."
              className="message-input"
            />
            <IconButton onClick={handleSendMessage} className="send-button">
              <SendIcon />
            </IconButton>
          </div>
        );
      default:
        return null;
    }
  };
  
  return (
    <div className={`popup-chat ${isOpen ? 'open' : ''}`} ref={chatRef}>
      {!isOpen && (
        <Tooltip title="Chat">
          <IconButton
            className={`toggle-button ${animateIcon ? 'animate-icon' : ''}`}
            style={{ background: !isOpen && '#6A58C6' }}
            onClick={toggleChat}
          >
            <ChatIcon style={{ color: '#fff' }} />
            <div className="badge"></div>
          </IconButton>
        </Tooltip>
      )}

      {isOpen && (
        <div className="chat-content">
          <div className="chat-header">
            <h2>Chat with Archie</h2>
            <IconButton className="close-chat" onClick={toggleChat}>
              <CloseIcon style={{ color: '#fff' }} />
            </IconButton>
          </div>

          <div className="message-container">
            {messages.map((message, index) => (
              <div 
                key={index} 
                className={`message ${isUserMessage(index, message) ? 'user-message' : 'bot-message'}`}
              >
                {message}
              </div>
            ))}
          </div>

          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', height: '50px' }}>
              <div className="loader"></div>
            </div>
          ) : showMessage ? (
            <div className="message bot-message">
              Your Query Is Received. You Will Be Replied Back Within 48 Hours.
              <br />
              Thank You.
              <br />
              Virtual Startup Campus.
            </div>
          ) : isNameEmailRequired ? (
            <div style={{ display: 'flex' }}>
              <div className="input-container" style={{ flexDirection: 'column' }}>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name..."
                  style={{ marginBottom: 2 }}
                  className="input-field"
                />
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email..."
                  style={{ marginBottom: 2 }}
                  className="input-field"
                />
                <input
                  type="text"
                  value={whatsapp}
                  onChange={(e) => setWhatsapp(e.target.value)}
                  placeholder="Enter your WhatsApp number..."
                  className="input-field"
                />
              </div>
              <div>
                <IconButton onClick={handleNameEmailSubmit}>
                  <SendIcon sx={{ color: '#6a58c6' }} />
                </IconButton>
              </div>
            </div>
          ) : (
            renderContent()
          )}
        </div>
      )}
    </div>
  );
};

export default PopupChat;